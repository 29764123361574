import Button from "@mui/material/Button";
import React from "react";
import { useMsal } from "@azure/msal-react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  loginButtonContainer: {
    position: "relative",
    top: "15vh",
    textAlign: "center",
  },
  loginButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.black,
    },
  },
}));

const LoginButton = () => {
  const { instance } = useMsal();
  const { classes } = useStyles();

  const handleSignin = () => {
    instance.loginRedirect({
      scopes: ["user.read"],
    });
  };

  return (
    <div className={classes.loginButtonContainer}>
      <Button id="login" onClick={handleSignin} className={classes.loginButton}>
        Login
      </Button>
    </div>
  );
};

export default LoginButton;
