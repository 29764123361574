import React from "react";
import {
  Clear,
  FlashOn,
  LocationOn,
  SearchOutlined,
} from "@mui/icons-material";
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import clsx from "clsx";
import { SEARCH_TYPE } from "../MDPViewer";
import { useStyles } from "../styles";

interface InputSearchType {
  setSearchText: (text: string) => void;
  searchText: string;
  inputSearch: () => Promise<void>;
  searchLoading: boolean;
  isPylonSearch: boolean;
  toggleSearchType: (type: SEARCH_TYPE) => void;
  resetSearch: () => void;
  searchHelperText: string;
}

export const InputSearch = ({
  setSearchText,
  searchText,
  inputSearch,
  searchLoading,
  isPylonSearch,
  toggleSearchType,
  resetSearch,
  searchHelperText,
}: InputSearchType) => {
  const { classes } = useStyles();
  return (
    <Box sx={{ position: "relative" }} m={2}>
      <Box
        sx={{ position: "absolute", top: "-12px", left: "8px" }}
        display="flex"
        gap={1}
        zIndex={1}
      >
        <Chip
          onClick={() => toggleSearchType(SEARCH_TYPE.PYLON)}
          color={isPylonSearch ? "error" : "secondary"}
          size="small"
          className={clsx({ [classes.iconChip]: !isPylonSearch })}
          label={isPylonSearch && "Asset"}
          icon={!isPylonSearch ? <FlashOn fontSize="small" /> : undefined}
          aria-label="Asset"
        />

        <Chip
          onClick={() => toggleSearchType(SEARCH_TYPE.DATASET)}
          color={!isPylonSearch ? "error" : "secondary"}
          size="small"
          className={clsx({ [classes.iconChip]: isPylonSearch })}
          label={!isPylonSearch && "Dataset"}
          icon={isPylonSearch ? <LocationOn fontSize="small" /> : undefined}
          aria-label="Dataset"
        />
      </Box>
      <Tooltip
        title={isPylonSearch ? "High Voltage Pylon Name" : "e.g. BI3034_M0010"}
      >
        <TextField
          fullWidth
          name="searchInput"
          className={classes.searchInput}
          InputProps={{
            inputProps: {
              "aria-label": "searchInput",
            },
            autoComplete: "true",
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  aria-label="search"
                  edge="start"
                  disabled={!searchText}
                  onClick={() => {
                    !searchLoading && inputSearch();
                  }}
                >
                  {searchLoading ? (
                    <CircularProgress size={24} aria-label="loader" />
                  ) : (
                    <SearchOutlined />
                  )}
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="clear" edge="end" onClick={resetSearch}>
                  <Clear />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              if (searchText.length > 0) !searchLoading && inputSearch();
            }
          }}
          placeholder={
            isPylonSearch
              ? "Search Datasets by asset name"
              : "Search Datasets by Dataset name"
          }
          autoComplete="off"
          error={searchHelperText !== ""}
          helperText={searchHelperText}
        />
      </Tooltip>
    </Box>
  );
};
