import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Zoom,
} from "@mui/material";

import { humanFileSize, string2Date } from "../../../util/utils";

import {
  CheckCircle,
  Close,
  CloudCircle,
  FlightTakeoff,
  Lock,
  Star,
  PhoneAndroid,
  Info,
  ArrowRight,
  ChevronRight,
} from "@mui/icons-material";
import clsx from "clsx";
import { DroneImageDetail, Metadata } from "../../../types/media";
import { SidebarWidth, useStyles } from "../styles";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { getDummyImage } from "./Results";

interface ResultsWindowProps {
  selectedObject: Metadata | null;
  setSelectedId: (id: string | null) => void;
  selectedImageDetails: DroneImageDetail | null;
}

export const ResultsWindow = ({
  selectedObject,
  setSelectedId,
  selectedImageDetails,
}: ResultsWindowProps) => {
  const { classes } = useStyles();
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(true);
  if (!selectedObject) return null;
  return (
    <Box className={classes.detailView}>
      {isDetailsOpen && (
        <IconButton
          sx={{
            zIndex: 1,
            position: "absolute",
            bgcolor: "#fff !important",
            right: `calc(${SidebarWidth} - 1.3rem)`,
            top: "50%",
            border: "1px solid #dddddd",
          }}
          onClick={() => {
            setIsDetailsOpen(false);
          }}
        >
          <ChevronRight />
        </IconButton>
      )}
      <IconButton
        sx={{
          zIndex: 1,
          position: "absolute",
          bgcolor: "#fff !important",
          right: isDetailsOpen ? `calc(${SidebarWidth} + 1rem)` : "3.5rem",
          top: 8,
        }}
        onClick={() => {
          setSelectedId(null);
        }}
      >
        <Close />
      </IconButton>
      <IconButton
        sx={{
          zIndex: 1,
          position: "absolute",
          bgcolor: "#fff !important",
          right: "0.5rem",
          top: 8,
        }}
        onClick={() => {
          setIsDetailsOpen((open) => !open);
        }}
      >
        <Info />
      </IconButton>
      <Box
        height={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        width={isDetailsOpen ? `calc(100% - ${SidebarWidth})` : "100%"}
      >
        <Box
          position="absolute"
          display="flex"
          justifyContent="center"
          height={50}
          alignItems="center"
        >
          <CircularProgress size={24} />
        </Box>
        {selectedImageDetails?.relatedMetadata?.dlPath && (
          <ImageViewer
            src={getDummyImage(`${selectedObject?.fileName}`, "full")}
            // src={selectedImageDetails?.relatedMetadata?.dlPath}
            isDetailsOpen={isDetailsOpen}
          />
        )}
      </Box>
      {isDetailsOpen && (
        <Box
          width={SidebarWidth}
          display="flex"
          flexDirection="column"
          borderLeft="1px solid #dddddd"
        >
          <Typography
            variant="h6"
            display="flex"
            alignItems="center"
            gap={1}
            sx={{ p: 2 }}
          >
            File Details
          </Typography>
          {/* <Box
            sx={{
              width: 1,
              height: "150px",
              background: "#e9e9e9",
            }}
            flexShrink={0}
          /> */}
          <Box p={2} display="flex" justifyContent="space-between">
            <Typography
              variant="h6"
              display="flex"
              alignItems="center"
              gap={1}
              sx={{ wordBreak: "break-word" }}
            >
              <FlightTakeoff /> {selectedObject?.fileName}
            </Typography>
            <Box display="flex" gap={1} alignItems="center">
              <CheckCircle color="secondary" />
              <img
                style={{
                  width: "70px",
                  height: "25px",
                  borderRadius: "2px",
                }}
                src="linia_logo.png"
              />
              {/* <Box
                sx={{
                  width: "70px",
                  height: "25px",
                  background: "#e9e9e9",
                  borderRadius: "2px",
                }}
              /> */}
            </Box>
          </Box>
          <Box
            className={classes.detailsInfo}
            display="flex"
            gap={1}
            px={2}
            flexDirection="column"
          >
            <Box display="flex" gap={1}>
              <CloudCircle />
              <Typography>
                {string2Date(selectedObject?.fileCreationDate)}
              </Typography>
            </Box>
            <Box display="flex" gap={1}>
              <PhoneAndroid />
              <Typography>
                {string2Date(selectedObject?.fileUploadDate)}
              </Typography>
            </Box>
            <Box display="flex" gap={1}>
              <Lock />
              <Typography>{humanFileSize(selectedObject?.fileSize)}</Typography>
            </Box>
            <Box display="flex" gap={1}>
              <Star />
              <Typography>{selectedObject?.relatedProcess?.name}</Typography>
            </Box>
          </Box>
          <Box
            m={2}
            overflow="hidden"
            display="flex"
            flexDirection="column"
            borderTop={1}
            borderColor="lightgray"
          >
            {/* <TextField
                size="small"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton aria-label="search" edge="start">
                        <SearchOutlined />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search Meta data"
                autoComplete="off"
              /> */}
            {selectedObject?.fileProcessingLevel !== "failed" &&
              selectedImageDetails === null && (
                <Box
                  display="flex"
                  justifyContent="center"
                  height={50}
                  alignItems="center"
                >
                  <CircularProgress size={24} />
                </Box>
              )}
            {selectedObject?.fileProcessingLevel !== "failed" &&
              selectedImageDetails && (
                <Box
                  className={clsx(
                    classes.detailsInfo,
                    classes.detailsInfoExtra,
                  )}
                  mt={1}
                  overflow="scroll"
                >
                  <TableContainer>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Typography color="black">File Details</Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell scope="row">Name</TableCell>
                          <TableCell>{selectedObject?.fileName}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell scope="row">Metadata Id</TableCell>
                          <TableCell>{selectedObject.id}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell scope="row">Process Id</TableCell>
                          <TableCell>{selectedObject.processId}</TableCell>
                        </TableRow>
                        {/* <TableRow>
                          <TableCell scope="row">Download Path</TableCell>
                          <TableCell>
                            <Link
                              target="_blank"
                              rel="noopener"
                              href={selectedObject.dlPath}
                              aria-label="Download link"
                            >
                              Download
                            </Link>
                          </TableCell>
                        </TableRow> */}
                        <TableRow>
                          <TableCell scope="row">Creation Date</TableCell>
                          <TableCell>
                            {string2Date(selectedObject.fileCreationDate)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TableContainer>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Typography color="black">
                              Drone Image Details
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.keys(selectedImageDetails)
                          .filter(
                            (item) =>
                              ![
                                "id",
                                "metadataId",
                                "relatedMetadata",
                                "url",
                              ].includes(item),
                          )
                          .map((attribute) => {
                            let value = selectedImageDetails[attribute];
                            if (attribute === "imageSize") {
                              value = `${value.join(" x ")} (W x H)`;
                            }
                            return (
                              <TableRow
                                key={attribute}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell scope="row">{attribute}</TableCell>
                                <TableCell>{value || "n/a"}</TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

interface ImageViewerProps {
  src: string;
  isDetailsOpen: boolean;
}

const ImageViewer = ({ src, isDetailsOpen }: ImageViewerProps) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [containerHeight, setContainerHeight] = useState<number>(0);

  const [imageNaturalWidth, setImageNaturalWidth] = useState<number>(0);
  const [imageNaturalHeight, setImageNaturalHeight] = useState<number>(0);

  const imageScale = useMemo(() => {
    if (
      containerWidth === 0 ||
      containerHeight === 0 ||
      imageNaturalWidth === 0 ||
      imageNaturalHeight === 0
    )
      return 0;
    const scale = Math.min(
      containerWidth / imageNaturalWidth,
      containerHeight / imageNaturalHeight,
    );
    return scale;
  }, [containerWidth, containerHeight, imageNaturalWidth, imageNaturalHeight]);

  const handleResize = useCallback(() => {
    if (container !== null) {
      const rect = container.getBoundingClientRect();
      setContainerWidth(rect.width);
      setContainerHeight(rect.height);
    } else {
      setContainerWidth(0);
      setContainerHeight(0);
    }
  }, [container, isDetailsOpen]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize, isDetailsOpen, src]);

  const handleImageOnLoad = (image: HTMLImageElement) => {
    setImageNaturalWidth(image.naturalWidth);
    setImageNaturalHeight(image.naturalHeight);
  };

  useEffect(() => {
    const image = new Image();
    image.onload = () => {
      handleImageOnLoad(image);
    };
    image.src = src;
  }, [src]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#dddddd",
      }}
      ref={(el: HTMLDivElement | null) => {
        if (el) {
          setContainer(el);
        }
      }}
    >
      {imageScale > 0 && (
        <TransformWrapper
          key={`${containerWidth}x${containerHeight}`}
          initialScale={imageScale}
          minScale={imageScale}
          maxScale={imageScale * 12}
          centerOnInit
        >
          <TransformComponent
            wrapperStyle={{
              width: "100%",
              height: "100%",
            }}
          >
            <img alt="image-view" src={src} />
          </TransformComponent>
        </TransformWrapper>
      )}
    </div>
  );
};
