import axios, { AxiosError, AxiosResponse } from "axios";
import { useMsal } from "@azure/msal-react";
import { useCallback } from "react";
import { ResponseForMethod } from "../types/grid";
import { isTestingWithJest } from "../util/testing";

export const useGridApi = () => {
  const { instance, accounts } = useMsal();

  const getBaseUrl = () => {
    return window.REACT_APP_API_GATEWAY_URL;
  };

  const getApiUrl = () => {
    return `${getBaseUrl()}/griddata/assets/hv-pylons/v2`;
  };

  const gridApiCall = useCallback(
    async <Path>(
      url: string,
      options = { absolute: false },
    ): Promise<ResponseForMethod<Path, "get">> => {
      let token;
      let tokenError;
      const tenantAppId = `${window.REACT_APP_TENANT_APP_ID}`;
      const apiUrl = options?.absolute ? url : `${getApiUrl()}` + url;
      try {
        const response = await instance.acquireTokenSilent({
          scopes: [`api://${tenantAppId}/.default`],
        });

        token = response.accessToken;
      } catch (error) {
        tokenError = error;
      }

      if (tokenError) {
        console.log("Error acquiring access token", tokenError);
        throw tokenError;
      }

      try {
        //const apiResponse = await axios.get(apiUrl, {
        const apiResponse = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return apiResponse.data;
      } catch (error: unknown) {
        const axiosError = error as AxiosError;
        if (!isTestingWithJest())
          console.log("Unhandled error in grid apiCall:", axiosError);
        throw axiosError;
        // return {
        //   error:
        //     axiosError.response ||
        //     axiosError.request ||
        //     axiosError.message ||
        //     axiosError,
        // };
      }
    },
    [instance],
  );

  return { gridApiCall, getApiUrl };
};
