import React, { FC, PropsWithChildren } from "react";
import { Box, Divider, useMediaQuery, useTheme } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import {
  NavItem,
  MobileNavigation,
  DesktopNavigation,
  EonHeader,
} from "@ipen-portal/portal-components";
import Account from "./auth/Account";
import { makeStyles } from "tss-react/mui";
import { AppSwitcher } from "./AppSwitcher";
import { CDN_IMAGES } from "../util/images";

const useStyles = makeStyles()((theme) => ({
  header: {
    borderBottom: "1px solid #dddddd",
  },
}));

const navigation: NavItem[] = [
  {
    icon: HomeIcon,
    target: "/",
    title: "Home",
  },
];

const Layout: FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        height: "100vh",
        maxHeight: "-webkit-fill-available",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>
        <EonHeader
          className={classes.header}
          logo={
            <Box
              height={1}
              width="auto"
              display="flex"
              alignItems="center"
              gap={1}
            >
              <Box height={1} width="auto" p={0.9} ml={1.3}>
                <img
                  src={CDN_IMAGES.logos("media_data_logos/md_viewer_icon.svg")}
                  height="100%"
                  width="auto"
                  alt="mdp-viewer"
                />
              </Box>
              <Divider
                orientation="vertical"
                sx={{ borderColor: "#cccbca" }}
                variant="middle"
                flexItem
              />
              <Box height={1} width="auto" p={0.9} ml={0.1}>
                <img
                  src={CDN_IMAGES.logos("ipen_logo/ipen_logo_main.svg")}
                  height="100%"
                  width="auto"
                  alt="IPEN"
                />
              </Box>
            </Box>
          }
        >
          <Account />
          <AppSwitcher />
        </EonHeader>
      </div>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          overflow: "hidden",
          height: "calc(100% - 56px)",
        }}
      >
        {isSmallScreen ? (
          <MobileNavigation items={navigation} />
        ) : (
          <DesktopNavigation items={navigation} />
        )}
        <Box
          sx={(theme) => ({
            height: "100%",
            flex: 1,
            overflow: "hidden",
          })}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};
export default Layout;
