import axios, { AxiosError, AxiosResponse } from "axios";
import { useMsal } from "@azure/msal-react";
import { useCallback } from "react";
import {
  ApiPlatformTenantsResponse,
  PlatformTenant,
  ResponseForMethod,
  paths,
} from "../types/media";
import { isTestingWithJest } from "../util/testing";

export const useApi = () => {
  const { instance, accounts } = useMsal();

  const getBaseUrl = () => {
    return window.REACT_APP_MEDIA_DATA_URL;
  };

  const getApiUrl = () => {
    return `${getBaseUrl()}/api/v1`;
  };

  const getOrganisations = async (limit?: number, offset?: number) => {
    let url = `/platformtenants`;
    if (limit || offset) {
      url = `${url}?limit=${limit}&offset=${offset}`;
    }
    const data = await apiCall<paths["/api/v1/platformtenants"]>(url);
    return data;
  };

  const getAllOrganisations = async (): Promise<PlatformTenant[] | null> => {
    const limit = 1000;
    let offset = 0;
    let response: ApiPlatformTenantsResponse;
    const organisations: PlatformTenant[] = [];
    do {
      response = await getOrganisations(limit, offset);
      if (response && response.results) {
        response.results.map((organisation) => {
          organisations.push(organisation);
        });
      }
      offset += limit;
    } while (response && response.next);
    return organisations;
  };

  const apiCall = useCallback(
    async <Path>(
      url: string,
      options = { absolute: false },
    ): Promise<ResponseForMethod<Path, "get">> => {
      let token;
      let tokenError;
      const tenantAppId = `${window.REACT_APP_TENANT_APP_ID}`;
      const apiUrl = options?.absolute ? url : `${getApiUrl()}` + url;
      try {
        const response = await instance.acquireTokenSilent({
          scopes: [`api://${tenantAppId}/.default`],
        });

        token = response.accessToken;
      } catch (error) {
        tokenError = error;
      }

      // if (tokenError) {
      //   console.log("Error acquiring access token", tokenError);
      //   throw tokenError;
      // }

      try {
        // return await fetch<T>(apiUrl, {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // })
        const apiResponse = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return apiResponse.data;
        // return apiResponse.data as T;
      } catch (error: unknown) {
        const axiosError = error as AxiosError;
        if (!isTestingWithJest())
          console.log(`Unhandled error in apiCall: ${apiUrl}`, axiosError);
        throw axiosError;
        // return {
        //   error:
        //     axiosError.response ||
        //     axiosError.request ||
        //     axiosError.message ||
        //     axiosError,
        // };
      }
    },
    [instance],
  );

  return { apiCall, getAllOrganisations, getApiUrl };
};
