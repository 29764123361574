import { components, paths } from "./schema";
export type { paths } from "./schema";

export type HttpVerb = "get" | "post" | "patch" | "delete" | "update";
export type ResponseForMethod<Path, Verb extends HttpVerb> =
  Path extends Record<
    Verb,
    {
      responses: {
        200: {
          content: {
            "application/json": infer ResponseType; // <-- the "infer"
          };
        };
      };
    }
  >
    ? ResponseType
    : never;

export interface Features {
  features: {
    geometry: {
      coordinates: number[][] | number[];
      type: string;
    };
  }[];
}

export enum PROCESS_PROCESSING_LEVEL {
  FAILED = "failed",
  PROCESSED = "processed",
}

export type PlatformTenant = components["schemas"]["PlatformTenant"];
export type Solution = components["schemas"]["Solution"];
export type Process = components["schemas"]["Process"];
export type Metadata = components["schemas"]["Metadata"];
export type DroneImageDetail = components["schemas"]["DroneImageDetail"];

export type ApiPlatformTenantsResponse = ResponseForMethod<
  paths["/api/v1/platformtenants"],
  "get"
>;
export type ApiSolutionsResponse = ResponseForMethod<
  paths["/api/v1/solutions"],
  "get"
>;
export type ApiProcessResponse = ResponseForMethod<
  paths["/api/v1/processes"],
  "get"
>;
export type ApiMetadataResponse = ResponseForMethod<
  paths["/api/v1/metadata"],
  "get"
>;
export type ApiDroneImageDetailResponse = ResponseForMethod<
  paths["/api/v1/droneimagedetails"],
  "get"
>;
