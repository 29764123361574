import { Box, Typography } from "@mui/material";
import React, { useMemo } from "react";

import clsx from "clsx";
import { useStyles } from "../styles";

const MapBaseLayers = {
  topo: {
    name: "Topology Map Layer",
    image: "/images/topo-view.png",
  },
  satellite: {
    name: "Satellite Images Layer",
    image: "/images/satellite-view.jpg",
  },
};

interface MapSwitcherProps {
  is3D: boolean;
  hasResults: boolean;
  setIsBaseLayer: React.Dispatch<React.SetStateAction<boolean>>;
  isBaseLayer: boolean;
  setIs3D: (is3D: boolean | ((v: boolean) => boolean)) => void;
}

export const MapSwitcher = ({
  is3D,
  hasResults,
  setIsBaseLayer,
  isBaseLayer,
  setIs3D,
}: MapSwitcherProps) => {
  const { classes } = useStyles();

  const otherLayer = useMemo(() => {
    return isBaseLayer ? MapBaseLayers.satellite : MapBaseLayers.topo;
  }, [isBaseLayer]);

  return (
    <Box
      className={clsx(classes.baseMapSwitcher, {
        [classes.baseMapSwitcherSide]: hasResults,
      })}
    >
      <button
        onClick={() => {
          // disabled but not removed as per request
          setIsBaseLayer((v) => !v);
        }}
        className={classes.mapControlToggle}
      >
        <img
          src={otherLayer.image}
          alt={otherLayer.name}
          className={classes.responsiveImage}
        />
      </button>
      <button
        onClick={() => setIs3D((v: boolean) => !v)}
        className={classes.mapControlToggle}
      >
        <Typography variant="h4" color="primary">
          {is3D ? "3D" : "2D"}
        </Typography>
      </button>
    </Box>
  );
};
