import * as Cesium from "cesium";

export const calculateLangLatBoundingBox = (latLongArray: number[][]) => {
  if (latLongArray.length === 0) {
    return null; // No points to create a bounding box
  }

  // Initialize min and max values with the first point
  let minLat = latLongArray[0][1];
  let maxLat = latLongArray[0][1];
  let minLng = latLongArray[0][0];
  let maxLng = latLongArray[0][0];

  // Iterate through the array to find min and max values
  for (let i = 1; i < latLongArray.length; i++) {
    const lat = latLongArray[i][1];
    const lng = latLongArray[i][0];

    // Update min and max values for latitude
    if (lat < minLat) minLat = lat;
    if (lat > maxLat) maxLat = lat;

    // Update min and max values for longitude
    if (lng < minLng) minLng = lng;
    if (lng > maxLng) maxLng = lng;
  }

  // Create and return the bounding box
  const boundingBox = {
    maxLng: maxLng, // east
    minLng: minLng, // west
    maxLat: maxLat, // north
    minLat: minLat, // south
  };

  return boundingBox;
};

export const calculateBoundingBox = (entities: Cesium.Entity[]) => {
  const langLatArray: number[][] = [];
  entities.forEach((item: Cesium.Entity) => {
    // console.log(item.point, item?.polygon);

    if (item?.billboard) {
      // point cartographic
      const position = item.position?.getValue(
        new Cesium.JulianDate(),
      ) as Cesium.Cartesian3;
      const cartographic = Cesium.Cartographic.fromCartesian(
        position,
        Cesium.Ellipsoid.WGS84,
      );

      langLatArray.push([
        Cesium.Math.toDegrees(cartographic.longitude),
        Cesium.Math.toDegrees(cartographic.latitude),
      ]);
    }

    // for point
    if (item?.point) {
      // point cartographic
      const position = item.position?.getValue(
        new Cesium.JulianDate(),
      ) as Cesium.Cartesian3;
      const cartographic = Cesium.Cartographic.fromCartesian(
        position,
        Cesium.Ellipsoid.WGS84,
      );

      langLatArray.push([
        Cesium.Math.toDegrees(cartographic.longitude),
        Cesium.Math.toDegrees(cartographic.latitude),
      ]);
    }

    // polygon
    if (item?.polygon) {
      const positions = item?.polygon.hierarchy?.getValue(
        new Cesium.JulianDate(),
      ).positions;
      positions.forEach((cartesian: Cesium.Cartesian3) => {
        // point cartographic
        const cartographic = Cesium.Cartographic.fromCartesian(
          cartesian,
          Cesium.Ellipsoid.WGS84,
        );

        langLatArray.push([
          Cesium.Math.toDegrees(cartographic.longitude),
          Cesium.Math.toDegrees(cartographic.latitude),
        ]);
      });
    }
  });
  return calculateLangLatBoundingBox(langLatArray);
};
