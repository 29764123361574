import React, { useEffect, useMemo, useState } from "react";
import { EllipsoidGraphics, Entity, PointGraphics } from "resium";
import * as Cesium from "cesium";
import { EntityGraphicsState } from "../MDPViewer";
import {
  ApiMetadataResponse,
  Features,
  Metadata,
  Process,
} from "../../../types/media";

interface CesiumGeoJsonCompProp {
  mapData: ApiMetadataResponse;
  is3D?: boolean;
  isPylonSearch?: boolean;
  clickedProcess?: Process | null;
}

const parse3DPos = (location: string) => {
  const coordinates: string[] | null = location.match(/\(([^)]+)\)/);
  if (!(Array.isArray(coordinates) && coordinates.length > 1)) return;
  const coordArray: number[] = coordinates[1]
    .split(" ")
    .map((item) => parseFloat(item));
  return Cesium.Cartesian3.fromDegrees(
    coordArray[0],
    coordArray[1],
    coordArray[2],
  );
};

export const MetadataGeoJsonComponent = ({
  mapData,
  isPylonSearch = false,
  is3D = false,
  clickedProcess = null,
}: CesiumGeoJsonCompProp) => {
  const [data, setData] = useState<Metadata[]>([]);

  useEffect(() => {
    if (mapData.results) {
      setData(mapData.results);
    } else {
      setData([]);
    }
  }, [mapData]);

  const metadata = useMemo(() => {
    const filterFn = clickedProcess
      ? (item) => item.processId === clickedProcess.id
      : () => true;
    return data.filter(filterFn).map((marker) => {
      const geoJson: Features = marker?.geoJson as unknown as Features;
      if (!geoJson.features[0].geometry) return null;
      const metadata = marker as Metadata;
      const pointCoords = Cesium.Cartesian3.fromDegreesArray(
        geoJson.features[0].geometry.coordinates as number[],
      );
      return (
        <React.Fragment key={metadata.id}>
          {is3D ? (
            <Entity
              id={metadata.id}
              position={parse3DPos(metadata.geom3D || "")}
              name={metadata.fileName}
              properties={{ type: "metadata_3d" }}
            >
              <EllipsoidGraphics
                {...EntityGraphicsState.NORMAL_Ellipsoid_Graphics}
              />
            </Entity>
          ) : (
            <Entity
              id={metadata.id}
              position={pointCoords[0]}
              name={metadata.fileName}
              properties={{ type: "metadata_2d" }}
            >
              <PointGraphics {...EntityGraphicsState.NORMAL_Point_Graphics} />
            </Entity>
          )}
        </React.Fragment>
      );
    });
  }, [data, is3D, clickedProcess]);

  // do not render if there is no data
  const length = data?.length;
  if (length != undefined && length <= 0) return null;
  if (isPylonSearch && clickedProcess === null) return null;

  return <>{metadata}</>;
};
