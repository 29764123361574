import React from "react";
import { Box, Chip, Tooltip } from "@mui/material";

import { FlightTakeoff, Satellite, ThreeDRotation } from "@mui/icons-material";
import { useStyles } from "../styles";

interface TopFiltersProps {
  selectedMediaDataType: string[];
  toggleSelectedMediaDataType: (type: string) => void;
}

export const TopFilters = ({
  selectedMediaDataType,
  toggleSelectedMediaDataType,
}: TopFiltersProps) => {
  const { classes } = useStyles();
  return (
    <Box className={classes.topControl}>
      <Tooltip
        title="Select filter to filter metadata by DroneImage type"
        placement="bottom"
      >
        <Chip
          label="Drone Images"
          // className={clsx(classes.topToggle, {[classes.topToggleActive]: topControlSelection.includes('1')})}
          onClick={() => toggleSelectedMediaDataType("DroneImage")}
          color={
            selectedMediaDataType.includes("DroneImage") ? "error" : "secondary"
          }
          icon={<FlightTakeoff fontSize="small" />}
        />
      </Tooltip>
      <Tooltip
        title="Select filter to filter metadata by Satellite Images type"
        placement="bottom"
      >
        <Chip
          label="Satellite Images"
          onClick={() => toggleSelectedMediaDataType("SatelliteImage")}
          color={
            selectedMediaDataType.includes("SatelliteImage")
              ? "error"
              : "secondary"
          }
          icon={<Satellite fontSize="small" />}
        />
      </Tooltip>
      <Tooltip
        title="Select filter to filter metadata by Point Cloud type"
        placement="bottom"
      >
        <Chip
          label="Point Clouds"
          onClick={() => toggleSelectedMediaDataType("PointCloud")}
          color={
            selectedMediaDataType.includes("PointCloud") ? "error" : "secondary"
          }
          icon={<ThreeDRotation fontSize="small" />}
        />
      </Tooltip>
    </Box>
  );
};
