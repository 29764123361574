import React from "react";
import { Entity, PointGraphics } from "resium";
import * as Cesium from "cesium";
import { EntityGraphicsState } from "../MDPViewer";

interface LocationGeoJsonComponent {
  locationData: GeoJSON.Point | null;
}

export const LocationGeoJsonComponent = ({
  locationData,
}: LocationGeoJsonComponent) => {
  if (!locationData) return null;
  return (
    <Entity
      id="my_location"
      position={Cesium.Cartesian3.fromDegreesArray(locationData.coordinates)[0]}
      name="User Location"
      properties={{ type: "my_location_2d" }}
    >
      <PointGraphics {...EntityGraphicsState.NORMAL_Red_Point_Graphics} />
    </Entity>
  );
};
