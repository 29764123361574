import React, { useEffect, useMemo, useState } from "react";
import { Entity, PolygonGraphics } from "resium";
import * as Cesium from "cesium";
import { EntityGraphicsState } from "../MDPViewer";
import { Features, Process } from "../../../types/media";

interface DatasetGeoJsonComponent {
  isPylonSearch: boolean;
  selectedProcesses: Process[];
  hoverProcessId: string | null;
  setClickedProcess: (process: Process | null) => void;
}

export const DatasetGeoJsonComponent = ({
  isPylonSearch,
  selectedProcesses,
  hoverProcessId,
  setClickedProcess,
}: DatasetGeoJsonComponent) => {
  const hoveredProcess = useMemo(() => {
    if (isPylonSearch && !hoverProcessId) return null;
    const process = selectedProcesses.find(
      (item) => item.id === hoverProcessId,
    );
    if (!process || !process?.geoJson) return null;
    return <ProcessGraphics process={process} />;
  }, [hoverProcessId, hoverProcessId, selectedProcesses]);

  const processes = useMemo(() => {
    return selectedProcesses.map((process) => {
      return (
        <ProcessGraphics
          process={process}
          key={process.id}
          onClick={() => {
            setClickedProcess(process);
          }}
        />
      );
    });
  }, [selectedProcesses]);
  return isPylonSearch ? hoveredProcess : processes;
};

export const ProcessGraphics: React.FunctionComponent<{
  process: Process;
  onClick?: () => void;
}> = ({ process, onClick = () => {} }) => {
  const geoJson: Features = process?.geoJson as unknown as Features;
  if (
    !geoJson ||
    !Array.isArray(geoJson.features) ||
    !geoJson.features[0].geometry
  )
    return null;
  const processCoords = Cesium.Cartesian3.fromDegreesArray(
    (geoJson.features[0].geometry.coordinates[0] as number[]).flat(),
  );

  return (
    <Entity
      key={process.id}
      id={process.id}
      name={process.name}
      onClick={onClick}
      properties={{ type: "process" }}
    >
      <PolygonGraphics
        hierarchy={processCoords}
        {...EntityGraphicsState.NORMAL_Polygon_graphics}
      />
    </Entity>
  );
};
