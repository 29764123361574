import React, { FC } from "react";
import Layout from "../components/Layout";
import { MDPViewer } from "./../viewers/MDPViewer";
// import GridDataAssetsViewerV2 from "../viewers/GridDataAssetsViewer";

type Props = Record<string, never>;

// eslint-disable-next-line no-empty-pattern
const Home: FC<Props> = ({}) => (
  <Layout>
    <MDPViewer />
    {/* <GridDataAssetsViewerV2 /> */}
  </Layout>
);

export default Home;
