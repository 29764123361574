import {
  Add,
  Explore,
  LocationDisabled,
  MyLocation,
  Remove,
} from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material";
import clsx from "clsx";
import React, { useCallback } from "react";
import * as Cesium from "cesium";
import { useCesium } from "resium";
import { makeStyles } from "tss-react/mui";
import { RIGHT_SIDE_SEARCH_BUTTON_ID } from "../MDPViewer";
import { Icon } from "@iconify/react";

export const useStyles = makeStyles()((theme) => ({
  root: {
    position: "absolute",
    zIndex: 10,
    right: theme.spacing(1),
    bottom: theme.spacing(5),
  },
  buttonSquare: {
    borderRadius: theme.spacing(1),
  },
  buttonNormal: {
    "&:hover": {
      color: "red",
    },
  },
  button: {
    boxShadow: theme.shadows[2],
    background: "white",
    "&:hover": {
      color: "red",
      background: "white",
    },
    "&:hover path": {
      fill: "red !important",
    },
  },
  buttonSvgCyan: {
    "& path": {
      fill: `${theme.palette.secondary.main} !important`,
    },
  },
}));

interface RightSideControlsProps {
  searchButtonId: string;
  setSearchButtonId: (searchButtonId: string) => void;
  searchButtonLoading: string;
  setSearchButtonLoading: (searchButtonId: string) => void;
  myLocation: GeoJSON.Point | null;
  setMyLocationWithOption: (options: {
    location: GeoJSON.Point | null;
    withSearch?: boolean;
  }) => void;
}

export const RightSideControls = ({
  searchButtonId,
  setSearchButtonId,
  setSearchButtonLoading,
  searchButtonLoading,
  setMyLocationWithOption,
  myLocation,
}: RightSideControlsProps) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const { viewer } = useCesium();

  const locateMe = useCallback(
    (withSearch: boolean = false) => {
      if (myLocation && !withSearch) {
        setMyLocationWithOption({ location: null });
        return;
      }
      if ("geolocation" in navigator) {
        setSearchButtonLoading(RIGHT_SIDE_SEARCH_BUTTON_ID.locateMe);
        navigator.geolocation.getCurrentPosition((position) => {
          setSearchButtonLoading("");
          const userLocation: GeoJSON.Point = {
            type: "Point",
            coordinates: [position.coords.longitude, position.coords.latitude],
          };
          setMyLocationWithOption({
            location: userLocation,
            withSearch,
          });
        });
      }
    },
    [myLocation],
  );

  const handleClick = useCallback(
    (id) => {
      switch (id) {
        case RIGHT_SIDE_SEARCH_BUTTON_ID.datasetInView: {
          setSearchButtonId(RIGHT_SIDE_SEARCH_BUTTON_ID.datasetInView);
          break;
        }
        case RIGHT_SIDE_SEARCH_BUTTON_ID.datasetViaAreaOfInterest: {
          setSearchButtonId(
            searchButtonId ===
              RIGHT_SIDE_SEARCH_BUTTON_ID.datasetViaAreaOfInterest
              ? ""
              : RIGHT_SIDE_SEARCH_BUTTON_ID.datasetViaAreaOfInterest,
          );
          break;
        }
        case RIGHT_SIDE_SEARCH_BUTTON_ID.userLocationBasedSearch: {
          // locate me
          locateMe(true);
          break;
        }
        case RIGHT_SIDE_SEARCH_BUTTON_ID.locateMe: {
          locateMe();
          break;
        }
        case "compass": {
          if (viewer) {
            // works only in 3D as in 2D top always facing north
            const cartesian = Cesium.Cartographic.toCartesian(
              viewer.camera.positionCartographic,
            );
            viewer.camera.flyTo({
              destination: cartesian,
              orientation: {
                heading: 0,
                pitch: viewer.camera.pitch,
                roll: 0.0,
              },
            });
          }
          break;
        }
        case "zoomIn": {
          //viewer?.camera.maximumZoomFactor
          if (viewer) {
            viewer.camera.positionWC.x > 1000 &&
              viewer.camera.zoomIn(100000.0 / 10);
          }
          break;
        }
        case "zoomOut": {
          if (viewer) {
            viewer.camera.zoomOut(100000.0 / 10);
          }
          break;
        }
      }
    },
    [locateMe, viewer, searchButtonId],
  );

  const getColor = (btnId: string) => {
    if (btnId === searchButtonId) {
      return "secondary";
    }
    return "default";
  };

  const getLocationIcon = () => {
    if (searchButtonLoading === RIGHT_SIDE_SEARCH_BUTTON_ID.locateMe) {
      return <CircularProgress size={24} />;
    }
    return myLocation ? <LocationDisabled /> : <MyLocation />;
  };

  return (
    <div className={classes.root}>
      <Box display="flex" flexDirection="column" gap={1} my={1}>
        <Tooltip
          title="Click this to search dataset in visible area"
          placement="left"
        >
          <IconButton
            aria-label={RIGHT_SIDE_SEARCH_BUTTON_ID.datasetInView}
            onClick={() =>
              handleClick(RIGHT_SIDE_SEARCH_BUTTON_ID.datasetInView)
            }
            size="small"
            className={clsx(classes.button)}
            color={getColor(RIGHT_SIDE_SEARCH_BUTTON_ID.datasetInView)}
          >
            {searchButtonLoading ===
            RIGHT_SIDE_SEARCH_BUTTON_ID.datasetInView ? (
              <CircularProgress size={24} />
            ) : (
              <Box width="20px">
                <Icon icon="ipen:media-data-extent-search" />
              </Box>
            )}
          </IconButton>
        </Tooltip>
        <Tooltip
          title="Click this to search dataset within selected area"
          placement="left"
        >
          <IconButton
            aria-label={RIGHT_SIDE_SEARCH_BUTTON_ID.datasetViaAreaOfInterest}
            onClick={() =>
              handleClick(RIGHT_SIDE_SEARCH_BUTTON_ID.datasetViaAreaOfInterest)
            }
            size="small"
            className={clsx(classes.button, {
              [classes.buttonSvgCyan]:
                searchButtonId ===
                RIGHT_SIDE_SEARCH_BUTTON_ID.datasetViaAreaOfInterest,
            })}
          >
            {searchButtonLoading ===
            RIGHT_SIDE_SEARCH_BUTTON_ID.datasetViaAreaOfInterest ? (
              <CircularProgress size={24} />
            ) : (
              <Box width="20px">
                <Icon icon="ipen:media-data-rectangle-search" />
              </Box>
            )}
          </IconButton>
        </Tooltip>
        <Tooltip
          title="Click this to search dataset at your location"
          placement="left"
        >
          <IconButton
            aria-label={RIGHT_SIDE_SEARCH_BUTTON_ID.userLocationBasedSearch}
            onClick={() =>
              handleClick(RIGHT_SIDE_SEARCH_BUTTON_ID.userLocationBasedSearch)
            }
            size="small"
            className={clsx(classes.button)}
          >
            {searchButtonLoading ===
            RIGHT_SIDE_SEARCH_BUTTON_ID.userLocationBasedSearch ? (
              <CircularProgress size={24} />
            ) : (
              <Box width="20px">
                <Icon icon="ipen:media-data-mylocation-search" />
              </Box>
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <Box display="flex" flexDirection="column" gap={1}>
        <Tooltip title="Locate your location" placement="left">
          <IconButton
            aria-label={RIGHT_SIDE_SEARCH_BUTTON_ID.locateMe}
            onClick={() => handleClick(RIGHT_SIDE_SEARCH_BUTTON_ID.locateMe)}
            size="small"
            className={clsx(classes.button, classes.buttonSquare)}
            color={myLocation ? "primary" : "default"}
          >
            {getLocationIcon()}
          </IconButton>
        </Tooltip>
        <Tooltip title="Reset map to north on at the top" placement="left">
          <IconButton
            aria-label="reset"
            onClick={() => handleClick("compass")}
            size="small"
            className={clsx(classes.button, classes.buttonSquare)}
          >
            <Explore />
          </IconButton>
        </Tooltip>
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            background: "white",
            borderRadius: theme.spacing(1),
            boxShadow: theme.shadows[2],
          }}
        >
          <Tooltip title="Zoom In" placement="left">
            <IconButton
              aria-label="zoom in"
              onClick={() => handleClick("zoomIn")}
              size="small"
              disableRipple
              className={classes.buttonNormal}
            >
              <Add />
            </IconButton>
          </Tooltip>
          <Box
            mx={1}
            sx={{
              background: theme.palette.grey[400],
              height: theme.spacing(0.3),
            }}
          />
          <Tooltip title="Zoom Out" placement="left">
            <IconButton
              aria-label="zoom out"
              onClick={() => handleClick("zoomOut")}
              size="small"
              disableRipple
              className={classes.buttonNormal}
            >
              <Remove />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </div>
  );
};

export const calculateSquarePolygon = (object: Cesium.Rectangle) => {
  const point1 = {
    latitude: Cesium.Math.toDegrees(object.north),
    longitude: Cesium.Math.toDegrees(object.west),
  };

  const point3 = {
    latitude: Cesium.Math.toDegrees(object.south),
    longitude: Cesium.Math.toDegrees(object.east),
  };

  const point2 = {
    latitude: point1.latitude,
    longitude: point3.longitude,
  };

  const point4 = {
    latitude: point3.latitude,
    longitude: point1.longitude,
  };

  // closed square polygon
  return [
    `${point1.longitude} ${point1.latitude}`,
    `${point2.longitude} ${point2.latitude}`,
    `${point3.longitude} ${point3.latitude}`,
    `${point4.longitude} ${point4.latitude}`,
    `${point1.longitude} ${point1.latitude}`,
  ];
};

export const getViewerViewArea = (viewer: Cesium.Viewer) => {
  const scratchRectangle = new Cesium.Rectangle();
  const ellipsoid = viewer.scene.globe.ellipsoid;
  let currentViewportRect = viewer.camera.computeViewRectangle(
    viewer.scene.globe.ellipsoid,
    scratchRectangle,
  );

  if (currentViewportRect === undefined) {
    const cr1 = new Cesium.Cartesian2(0, 0);
    const leftTop = viewer.scene.camera.pickEllipsoid(cr1, ellipsoid);

    const cr2 = new Cesium.Cartesian2(
      viewer.scene.canvas.width,
      viewer.scene.canvas.height,
    );
    const rightDown = viewer.scene.camera.pickEllipsoid(cr2, ellipsoid);

    if (leftTop && rightDown) {
      const leftTopCartography = ellipsoid.cartesianToCartographic(leftTop);
      const rightDownCartography = ellipsoid.cartesianToCartographic(rightDown);
      currentViewportRect = new Cesium.Rectangle(
        leftTopCartography.longitude,
        rightDownCartography.latitude,
        rightDownCartography.longitude,
        leftTopCartography.latitude,
      );
    }
  }

  // closed square polygon
  const polyArray =
    currentViewportRect !== undefined
      ? calculateSquarePolygon(currentViewportRect)
      : [];
  return polyArray.join(", ");
};

export const areaOfInterestLogic = async (
  viewer: Cesium.Viewer,
  screenSpaceEventHandler: Cesium.ScreenSpaceEventHandler,
  selectorRect: Cesium.Entity | null,
  userSelectingRef: React.MutableRefObject<boolean>,
  onSelectionComplete: (polygonString: string) => Promise<void>,
) => {
  viewer.scene.screenSpaceCameraController.enableTranslate = false;
  let selectionStart = false;
  let cartesian = new Cesium.Cartesian3();
  let tempCartographic = new Cesium.Cartographic();
  let firstPointSet = false;

  const firstPoint = new Cesium.Cartographic();
  const rectangleSelector = new Cesium.Rectangle();

  // click
  screenSpaceEventHandler.setInputAction(async () => {
    /* coord */
    if (selectorRect?.rectangle) {
      if (!selectionStart) {
        selectionStart = true;
        userSelectingRef.current = true;
        viewer.scene.screenSpaceCameraController.enableTranslate = false;
        selectorRect.rectangle.coordinates = new Cesium.CallbackProperty(
          function getSelectorLocation(_time, result) {
            return Cesium.Rectangle.clone(rectangleSelector, result);
          },
          false,
        );
      } else {
        selectorRect.show = false;
        viewer.scene.screenSpaceCameraController.enableTranslate = true;

        // closed square polygon
        const polyArray = calculateSquarePolygon(rectangleSelector);
        await onSelectionComplete(polyArray.join(", "));

        userSelectingRef.current = false;
      }
    }
  }, Cesium.ScreenSpaceEventType.LEFT_DOWN);

  // mouse move
  screenSpaceEventHandler.setInputAction(
    (movement: Cesium.ScreenSpaceEventHandler.MotionEvent) => {
      if (!selectionStart) return;

      cartesian = viewer.camera.pickEllipsoid(
        movement.endPosition,
        viewer.scene.globe.ellipsoid,
        cartesian,
      ) as Cesium.Cartesian3;

      // mouse cartographic
      tempCartographic = Cesium.Cartographic.fromCartesian(
        cartesian,
        Cesium.Ellipsoid.WGS84,
        tempCartographic,
      );

      if (!firstPointSet) {
        Cesium.Cartographic.clone(tempCartographic, firstPoint);
        firstPointSet = true;
      } else {
        rectangleSelector.east = Math.max(
          tempCartographic.longitude,
          firstPoint.longitude,
        );
        rectangleSelector.west = Math.min(
          tempCartographic.longitude,
          firstPoint.longitude,
        );
        rectangleSelector.north = Math.max(
          tempCartographic.latitude,
          firstPoint.latitude,
        );
        rectangleSelector.south = Math.min(
          tempCartographic.latitude,
          firstPoint.latitude,
        );
        if (selectorRect) selectorRect.show = true;
      }
    },
    Cesium.ScreenSpaceEventType.MOUSE_MOVE,
  );
};
